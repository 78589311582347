<template>
    <v-container class="trello-board pl-0 pl-16" fluid>
        <div v-if="!currentBoard">
            <v-btn class="mb-3" color="primary" @click="openBoardDialog()">Create Board</v-btn>
            <v-row>
                <v-col v-for="board in boards" :key="board.id" cols="3">
                    <v-card @click="selectBoard(board)" class="board-card">
                        <v-card-title>{{ board.name }}</v-card-title>
                        <v-chip :color="board.visibility === 'public' ? 'green' : 'red'">
                            {{ board.visibility === 'public' ? 'Public' : 'Private' }}
                        </v-chip>
                    </v-card>
                </v-col>
            </v-row>
        </div>

        <!-- Selected Board and Lists -->
        <div v-if="currentBoard">
            <v-row>
                <v-col cols="12" class="d-flex justify-space-between align-center">
                    <div class="d-flex align-center">
                        <v-btn icon @click="goBackToBoards">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <h3>{{ currentBoard.name }}</h3>
                    </div>
                    <v-btn color="primary" @click="openListDialog()">Add List</v-btn>
                </v-col>
            </v-row>

            <v-row>
                <!-- Draggable Lists -->
                <draggable v-model="currentBoard.lists" class="drag-area" group="lists" @end="updateBoard"
                    style="display: content;">
                    <v-col v-for="list in currentBoard.lists" :key="list.id" cols="4" class="pa-2">
                        <v-card class="list-card pa-0">
                            <v-card-title class="list-header">
                                {{ list.name }}
                                <v-btn icon small @click.stop="deleteList(list.id)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-card-title>

                            <!-- Draggable Cards -->
                            <v-list dense class="card-list">
                                <draggable v-model="list.cards" group="cards" @end="updateBoard"
                                    style="display: content;">
                                    <v-list-item v-for="card in list.cards" :key="card.id" class="card-item">
                                        <v-card>
                                            <v-card-title class="card-title">
                                                {{ card.name }}
                                            </v-card-title>
                                            <v-card-subtitle class="card-subtitle">
                                                <v-chip v-for="label in card.labels" :key="label" :color="label.color"
                                                    small>
                                                    {{ label.text }}
                                                </v-chip>
                                                <div class="card-footer">
                                                    <v-avatar v-for="user in card.users" :key="user.id" size="24"
                                                        class="mr-1">
                                                        <img :src="user.avatar" alt="">
                                                    </v-avatar>
                                                    <v-btn icon small @click.stop="openCardDialog(list.id, card)">
                                                        <v-icon>mdi-pencil</v-icon>
                                                    </v-btn>
                                                    <v-btn icon small @click.stop="deleteCard(list.id, card.id)">
                                                        <v-icon>mdi-delete</v-icon>
                                                    </v-btn>
                                                </div>
                                            </v-card-subtitle>
                                        </v-card>
                                    </v-list-item>
                                </draggable>
                            </v-list>
                            <v-btn text small @click="openCardDialog(list.id)">Add Card</v-btn>
                        </v-card>
                    </v-col>
                </draggable>
            </v-row>
        </div>

        <!-- Dialog for Creating/Editing Board -->
        <v-dialog v-model="boardDialog" max-width="500px">
            <v-card>
                <v-card-title>{{ isEditingBoard ? 'Edit Board' : 'Create Board' }}</v-card-title>
                <v-card-text>
                    <v-text-field label="Board Name" v-model="newBoardData.name"></v-text-field>
                    <v-select v-model="newBoardData.visibility" :items="['public', 'private']"
                        label="Visibility"></v-select>
                    <v-select v-if="newBoardData.visibility === 'private'" v-model="newBoardData.allowedUsers"
                        :items="adminUsers" label="Allowed Users" multiple item-text="email" item-value="id"
                        chips></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="saveBoard">{{ isEditingBoard ? 'Save' : 'Create' }}</v-btn>
                    <v-btn @click="boardDialog = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog for Creating/Editing List -->
        <v-dialog v-model="listDialog" max-width="500px">
            <v-card>
                <v-card-title>{{ isEditingList ? 'Edit List' : 'Create List' }}</v-card-title>
                <v-card-text>
                    <v-text-field label="List Name" v-model="newListData.name"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="saveList">{{ isEditingList ? 'Save' : 'Create' }}</v-btn>
                    <v-btn @click="listDialog = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog for Creating/Editing Card -->
        <v-dialog v-model="cardDialog" max-width="500px">
            <v-card>
                <v-card-title>{{ isEditingCard ? 'Edit Card' : 'Create Card' }}</v-card-title>
                <v-card-text>
                    <v-text-field label="Card Name" v-model="newCardData.name"></v-text-field>
                    <v-date-picker v-model="newCardData.dueDate" label="Due Date"></v-date-picker>
                    <v-select v-model="newCardData.labels" :items="labelOptions" label="Labels" multiple chips
                        item-text="text" item-value="color"></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" @click="saveCard">{{ isEditingCard ? 'Save' : 'Create' }}</v-btn>
                    <v-btn @click="cardDialog = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { getFirestore, collection, doc, addDoc, updateDoc, getDocs, deleteDoc, query, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import draggable from "vuedraggable";
import { v4 as uuidv4 } from 'uuid'; // For generating unique IDs

export default {
    components: { draggable },
    data() {
        return {
            boards: [],
            currentBoard: null,
            boardDialog: false,
            listDialog: false,
            cardDialog: false,
            isEditingBoard: false,
            isEditingList: false,
            isEditingCard: false,
            newBoardData: { name: '', visibility: 'public', allowedUsers: [], lists: [] },
            newListData: { name: '' },
            newCardData: { name: '', dueDate: null, labels: [] },
            adminUsers: [],
            currentUser: null,
            labelOptions: [
                { text: "Urgent", color: "red" },
                { text: "In Progress", color: "blue" },
                { text: "Completed", color: "green" },
            ],
        };
    },
    methods: {
        async fetchBoards() {
            const db = getFirestore();
            const querySnapshot = await getDocs(collection(db, "boards"));
            this.boards = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        },
        async fetchAdminUsers() {
            const db = getFirestore();
            const auth = getAuth();
            this.currentUser = auth.currentUser;

            const adminQuery = query(collection(db, 'users'), where('type', '==', 'admin'));
            const querySnapshot = await getDocs(adminQuery);

            this.adminUsers = querySnapshot.docs
                .filter(doc => doc.id !== this.currentUser.uid)
                .map(doc => ({ id: doc.id, ...doc.data() }));
        },
        selectBoard(board) {
            this.currentBoard = { ...board };
            // Update the URL when a board is selected
            const formattedName = this.formatBoardName(board.name);
            this.$router.push(`/boards/${formattedName}`);
        },
        formatBoardName(name) {
            return name.toLowerCase().replace(/\s+/g, '-');
        },
        goBackToBoards() {
            this.currentBoard = null;
            this.$router.push('/boards');
        },
        async loadBoardFromURL() {
            //in router /boards/:boardName

            const boardName = this.$route.params.boardName;
            if (!boardName) return;

            const board = this.boards.find(board => this.formatBoardName(board.name) === boardName);
            if (board) {
                this.selectBoard(board);
            }




        },
        async saveBoard() {
            const db = getFirestore();

            if (this.isEditingBoard) {
                const boardRef = doc(db, "boards", this.currentBoard.id);
                await updateDoc(boardRef, this.newBoardData);
            } else {
                await addDoc(collection(db, "boards"), { ...this.newBoardData, createdDate: new Date() });
            }

            this.boardDialog = false;
            this.fetchBoards();
        },
        async updateBoard() {
            const db = getFirestore();
            const boardRef = doc(db, "boards", this.currentBoard.id);
            await updateDoc(boardRef, { lists: this.currentBoard.lists });
        },
        async deleteList(listId) {
            this.currentBoard.lists = this.currentBoard.lists.filter(list => list.id !== listId);
            await this.updateBoard();
        },
        async saveList() {
            if (this.isEditingList) {
                this.isEditingList = false;
            } else {
                this.currentBoard.lists.push({ id: uuidv4(), name: this.newListData.name, cards: [] });
            }

            this.listDialog = false;
            await this.updateBoard();
        },
        async deleteCard(listId, cardId) {
            const list = this.currentBoard.lists.find(list => list.id === listId);
            list.cards = list.cards.filter(card => card.id !== cardId);
            await this.updateBoard();
        },
        async saveCard() {
            if (this.isEditingCard) {
                this.isEditingCard = false;
            } else {
                const list = this.currentBoard.lists.find(list => list.id === this.newCardData.listId);
                list.cards.push({ id: uuidv4(), ...this.newCardData });
            }

            this.cardDialog = false;
            await this.updateBoard();
        },
        openBoardDialog() {
            this.isEditingBoard = false;
            this.newBoardData = { name: '', visibility: 'public', allowedUsers: [], lists: [] };
            this.boardDialog = true;
        },
        openListDialog() {
            this.isEditingList = false;
            this.newListData = { name: '' };
            this.listDialog = true;
        },
        openCardDialog(listId, card = null) {
            this.isEditingCard = !!card;
            this.newCardData = card ? { ...card } : { name: '', dueDate: null, labels: [], listId };
            this.cardDialog = true;
        },

    },
    async created() {
        await this.fetchBoards();
        await this.fetchAdminUsers();
        await this.loadBoardFromURL(); // Load the board based on the URL
    },
};
</script>



<style scoped>



.drag-area {
    display: flex;
    gap: 1rem;
}

.card-item {
    margin-bottom: 10px;
}

.card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
